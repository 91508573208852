/*=========================================================================================
  File Name: moduleCalendarState.js
  Description: Calendar Module State
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  // products: [],
  bookings:[],
  upcomingBookings:[],
  awaitingPlaceActionBookings:[],
  awaitingCustomerActionBookings: [],
  lastAddedBookings: [],
  cancelledBookings: [],
  endedBookings: [],
  bookingStates:[],
  pages: null,
  bookingOptionsList: [],
  bookingOption: {},
  eventTypesList: [],
  venueRoomsList: [],
  receipt: null,
  isLoading: false,
  totals: [],
  booking: null,
  selectedOptionServicesList: [],
  multiVenueBookings: [],
}
