/*=========================================================================================
  File Name: moduleCalendarActions.js
  Description: Calendar Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from '@/axios.js'

function deriveErrorMessageFromErrorObject(error){
  return (!!error.response.data && !!error.response.data.error_message) ? error.response.data.error_message : 'Error';
}
export default {
  addItem ({ commit }, {venueId, request}) {
    return new Promise((resolve, reject) => {
      axios.post(`/venues/${venueId}/bookings`, {...request})
        .then((response) => {
          // var resp = response.data.data;
          let resp = Array.isArray(response)? response[0].data.data:
                                              response.data.data;
          commit('ADD_ITEM', Object.assign(resp.booking))
          commit('ADD_RECEIPT', Object.assign(resp.receipt))
          commit('SET_SELECTED_OPTION_SERVICES_LIST', [])
          this.dispatch('dataList/getUpcomingBookings', {venueId: venueId});
          resolve(response)
        })
        .catch((error) => {
          const errorMessage = deriveErrorMessageFromErrorObject(error)

          reject(errorMessage)
        })
    })
  },
  updateBooking ({ commit }, {venueId, request, bookingId, receiptId}) {
    return new Promise((resolve, reject) => {
      axios.put(`/venues/${venueId}/bookings/${bookingId}/${receiptId}`, {...request})
        .then((response) => {
          let resp = Array.isArray(response)? response[0].data.data:
                                              response.data.data;
          commit('UPDATE_BOOKING_IN_ALL_LISTS_OF_BOOKINGS', resp.booking)
          commit('ADD_RECEIPT', Object.assign(resp.receipt))
          commit('SET_SELECTED_OPTION_SERVICES_LIST', [])
          this.dispatch('dataList/getUpcomingBookings', {venueId: venueId});
          resolve(response)
        })
        .catch((error) => {
          const errorMessage = deriveErrorMessageFromErrorObject(error)

          reject(errorMessage)
        })
    })
  },
  updateReceipt ({ commit }, {venueId, receipt, stateId = 1, cancelationReason = ''}) { // 1 is temp state (default for now)
    return new Promise((resolve, reject) => {
      axios.put(`/venues/${venueId}/bookings/${receipt.booking_id}/receipts/${receipt.id}`, {...receipt, 'state_id': stateId, 'cancelation_reason': cancelationReason})
        .then((response) => {
          var resp = response.data.data;
          commit('UPDATE_PRODUCT', Object.assign(resp.booking))
          this.dispatch('dataList/getUpcomingBookings', {venueId: venueId});
          commit('ADD_RECEIPT', Object.assign(resp.receipt))
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  updateState ({ commit }, {venueId, bookingId, stateId, cancelationReason = '', suggestedDates, otherCancelationReason, confirmation, smsMessage, what_to_block}) {
    return new Promise((resolve, reject) => {
      let request_payload = {'state_id': stateId, 'cancelation_reason': cancelationReason, 'suggested_dates': suggestedDates, 'other_cancelation_reason': otherCancelationReason, 'confirmation': confirmation};
      if(smsMessage) {
        request_payload.sms_message = smsMessage;
      }
      if(what_to_block) {
        request_payload.what_to_block = what_to_block;
      }
      axios.post(`/venues/${venueId}/bookings/${bookingId}/updateState`, request_payload)
        .then((response) => {
          var resp = response.data.data;
          commit('UPDATE_PRODUCT', Object.assign(resp.booking))
          this.dispatch('dataList/getUpcomingBookings', {venueId: venueId});
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  getUpcomingBookings ({ commit }, {venueId}) {
    let startDate = new Date().toISOString().split('T')[0];

    let endDate = new Date(new Date().setDate(new Date().getDate() + 365)).toISOString().split('T')[0];
    let yesterday = new Date(new Date().setDate(new Date().getDate() - 1)).toISOString().split('T')[0];
    let lastYear = new Date(new Date().setDate(new Date().getDate() - 365)).toISOString().split('T')[0];

    let soryKey = 'start_date';
    let sortType = 'asc';
    let limit = 5;
    this.dispatch('dataList/fetchDataListItems', {venueId: venueId, getAllPages: true, startDate: startDate, endDate: endDate, soryKey: soryKey, sortType: sortType, setState: 'SET_UPCOMING_BOOKINGS', limit: limit});
    this.dispatch('dataList/fetchDataListItems', {venueId: venueId, getAllPages: true, startDate: startDate, endDate: endDate, soryKey: soryKey, sortType: sortType, setState: 'SET_AWAITING_PLACE_ACTION_BOOKINGS', statesConstant: 'awaitingPlaceAction', limit: limit});
    this.dispatch('dataList/fetchDataListItems', {venueId: venueId, getAllPages: true, startDate: startDate, endDate: endDate, soryKey: soryKey, sortType: sortType, setState: 'SET_AWAITING_CUSTOMER_ACTION_BOOKINGS', statesConstant: 'awaitingCustomerActionBookings', limit: limit});
    this.dispatch('dataList/fetchDataListItems', {venueId: venueId, getAllPages: true, startDate: startDate, endDate: endDate, soryKey: 'created_at', sortType: 'desc', setState: 'SET_LAST_ADDED_BOOKINGS', limit: limit});
    this.dispatch('dataList/fetchDataListItems', {venueId: venueId, getAllPages: true, startDate: startDate, endDate: endDate, soryKey: soryKey, sortType: sortType, setState: 'SET_CANCELLED_BOOKINGS', limit: limit, states: [6,19,20,21]});
    this.dispatch('dataList/fetchDataListItems', {venueId: venueId, getAllPages: true, startDate: lastYear, endDate: yesterday, soryKey: soryKey, sortType: sortType, setState: 'SET_ENDED_BOOKINGS', limit: 10});
  },
  fetchDataListItems ({ commit }, {venueId, page = 1, soryKey='created_at',sortType='desc', startDate=null, endDate=null, states=null, getAllPages = false, statesConstant = null, setState = 'SET_PRODUCTS', limit = null}) {
    return new Promise((resolve, reject) => {
      // console.log(venueId, '======== fetchDataListItems ===========')
      // console.log(axios.defaults.headers.common)
      commit('RUN_LOADING')

      let url = `/venues/${venueId}/bookings?page=${page}`;
      let param = new URLSearchParams();

      if(soryKey !== null){
        param.append('page', page);
        param.append('sort', soryKey);
        param.append('order', sortType);
      }

      if(startDate !== null && endDate !== null){
        param.append('start_date', startDate);
        param.append('end_date', endDate);
      }

      if(states !== null){
        states.forEach((state) => {
          param.append('booking_states[]', state)
        })
      }

      if(getAllPages == true){
        param.append('all_data', true)
      }

      if(statesConstant !== null){
        param.append('booking_states_constant', statesConstant)
      }

      if(limit !== null) {
        param.append('limit', limit)
      }

      url = `/venues/${venueId}/bookings?${param.toString()}`;
      axios.get(url)
        .then((response) => {
          commit('SET_TOTALS', response.data.totals)
          commit(setState, response.data.data)
          if(response.data.meta) {
            commit('SET_PAGES', {
              current_page: response.data.meta.current_page,
              from: response.data.meta.from,
              last_page: response.data.meta.last_page,
              total: response.data.meta.total,
              per_page: response.data.meta.per_page,
              next_link: response.data.links.next,
            });
          }
          commit('STOP_LOADING')
          resolve(response)
        })
        .catch((error) => {
          console.log(error, "from store :(")
          reject(error)
        })
    })
  },

  fetchCalendarBookings ({ commit }, {venueId, date}) {
    return new Promise((resolve, reject) => {
      commit('RUN_LOADING')
      let url = `/venues/${venueId}/bookings?date=${date}`
      axios.get(url)
        .then((response) => {
          // console.log(response.data);
          commit('SET_BOOKINGS', response.data.data)
          commit('STOP_LOADING')
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  fetchCalendarBookingsMultiVenues ({ commit }, {ids, date}) {
    return new Promise((resolve, reject) => {
      commit('RUN_LOADING')
      let params = new URLSearchParams();
      params.append('date', date);
      ids.forEach(id => params.append('venues[]', id));

      let url = `/bookings/getMultiVenueCalendarBookings?${params.toString()}`;
      axios.get(url)
        .then((response) => {
          commit('SET_MULTI_VENUE_BOOKINGS', response.data.data)
          commit('STOP_LOADING')
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  search ({ commit }, {venueId, query}) {
    return new Promise((resolve, reject) => {
      axios.post(`/venues/${venueId}/bookings/search`, {query})
        .then((response) => {
          // console.log(response.data.data);
          commit('SET_PRODUCTS', response.data.data)
          commit('SET_PAGES', {
            current_page: response.data.meta.current_page,
            from: response.data.meta.from,
            last_page: response.data.meta.last_page,
            total: response.data.meta.total,
            per_page: response.data.meta.per_page,
            next_link: response.data.links.next,
          });
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  fetchDataItem ({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios.get(`/venues/${item.venueId}/bookings/${item.bookingId}`)
        .then((response) => {
          var resp = response.data.data;
          // commit('SET_PRODUCTS', response.data.data)
          commit('UPDATE_PRODUCT', Object.assign(resp))
          this.dispatch('dataList/getUpcomingBookings', {venueId: item.venueId});
          commit('ADD_RECEIPT', Object.assign(resp.receipt))
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  getBooking ({ commit }, {bookingId, venueId}) {
    return new Promise((resolve, reject) => {
      axios.get(`/venues/${venueId}/bookings/${bookingId}`)
        .then((response) => {
          let booking = response.data.data;

          commit('SET_BOOKING', Object.assign(booking))
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  // fetchEventLabels({ commit }) {
  //   return new Promise((resolve, reject) => {
  //     axios.get("/api/apps/calendar/labels")
  //       .then((response) => {
  //         commit('SET_LABELS', response.data)
  //         resolve(response)
  //       })
  //       .catch((error) => { reject(error) })
  //   })
  // },
  updateItem ({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios.put(`/venues/${item.venue_id}/bookings/${item.id}`, {...item})
        .then((response) => {
          commit('UPDATE_PRODUCT', response.data.data.booking)
          this.dispatch('dataList/getUpcomingBookings', {venueId: item.venueId});
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  removeItem ({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios.delete(`/venues/${item.venueId}/bookings/${item.id}`)
        .then((response) => {
          commit('REMOVE_ITEM', item.id)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  deleteBookingBlock ({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios.delete(`/venues/${item.venue_id}/bookings/${item.id}/deleteBookingBlock`)
        .then((response) => {
          commit('REMOVE_ITEM', item.id)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  // eventDragged({ commit }, payload) {
  //   return new Promise((resolve, reject) => {
  //     axios.post(`/api/apps/calendar/event/dragged/${payload.event.id}`, {payload: payload})
  //       .then((response) => {

  //         // Convert Date String to Date Object
  //         let event = response.data
  //         event.startDate = new Date(event.startDate)
  //         event.endDate = new Date(event.endDate)

  //         commit('UPDATE_EVENT', event)
  //         resolve(response)
  //       })
  //       .catch((error) => { reject(error) })
  //   })
  // },
  fetchBookingStatesList({commit}){
    return new Promise((resolve, reject) => {
      axios.get(`/availableBookingStates`)
        .then((response) => {
          commit('SET_BOOKING_STATES_LIST', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  fetchbookingOptionsList({commit}, venueId){
    return new Promise((resolve, reject) => {
      axios.get(`/venues/${venueId}/bookingOptions`)
        .then((response) => {
          commit('SET_BOOKING_OPTIONS_LIST', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  fetchEventTypesList({commit}){
    return new Promise((resolve, reject) => {
      axios.get(`/EventTypes`)
        .then((response) => {
          commit('SET_EVENT_TYPES_LIST', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  fetchVenueRoomsList({commit}, venueId){
    return new Promise((resolve, reject) => {
      axios.get(`/venues/${venueId}/rooms`)
        .then((response) => {
          commit('SET_VENUE_ROOMS_LIST', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  runLoading({commit}){
    commit('RUN_LOADING');
  },
  stopLoading({commit}){
    commit('STOP_LOADING');
  },
  getBookingOption ({ commit }, {venue: venueId, option_id: optionId}) {
    return new Promise((resolve, reject) => {


      axios.get(`/venues/${venueId}/bookingOptions/${optionId}`)
        .then((response) => {
          commit('SET_BOOKING_OPTION', response.data.bookingOption)
          resolve(response)
        })
        .catch((error) => {
          console.log(error, "from store :(", error)
          reject(error)
        })
    })
  },
  changeLeadBookingState ({ commit }, {venueId, booking, action = 'block'}) {
    return new Promise((resolve, reject) => {

      const request = new FormData();
      request.append('action', action);
      axios.post(`/venues/${venueId}/bookings/${booking.id}/changeLeadsState`, request)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          console.log(error, "from store :(", error)
          reject(error)
        })
    })
  },
  async getSelectedOptionServicesList({commit}, {venue_id, option_id, counts}) {
    try {
      let params = new URLSearchParams();
      if(counts.men_count) params.append('men_count', counts.men_count);
      if(counts.women_count) params.append('women_count', counts.women_count);
      if(counts.people_count) params.append('people_count', counts.people_count);
      const response = await axios.get(`/venues/${venue_id}/bookingOptions/${option_id}/get_selected_option_services_list?${params}`);
      commit('SET_SELECTED_OPTION_SERVICES_LIST', response.data.services);
      return response;
    } catch (error) {
        return error.response;
    }

  },
  async cancelBooking({commit}, {venue_id, booking_id, data}) {
    try {

      const payload = new FormData();

      payload.append('state_name', data.state_name);

      if(data.refund_amount)
        payload.append('refund_amount', data.refund_amount);

      const response = await axios.post(`/venues/${venue_id}/bookings/${booking_id}/cancel`, payload);

      commit('UPDATE_PRODUCT', Object.assign(response.data.booking))
      this.dispatch('dataList/getUpcomingBookings', {venueId: venue_id});
      commit('ADD_RECEIPT', Object.assign(response.data.receipt));

      return response;
    } catch (error) {
        return error.response;
    }

  }
}
